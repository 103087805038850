import React from "react";
import "./Loader.css";

export const Loader = () => {
    return (
        <div className="lds-center">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}